import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function ContactForm({ btnText }) {
	const [state, handleSubmit] = useForm("mleovnel");

	const handleFormSubmit = (event) => {
		// Capture form data
		const formData = new FormData(event.target);
		const customerEmail = formData.get("_replyto");
		const customerPhoneNumber = formData.get("telephone");
		const customerName = formData.get("name");

		// Call the formspree handleSubmit
		handleSubmit(event).then(() => {
			// Push to dataLayer after form submit
			if (typeof window !== "undefined" && window.dataLayer) {
				window.dataLayer.push({
					event: "generate_lead",
					form_name: "ContactForm", // You can change this based on the form name
					customer_email: customerEmail,
					customer_phone_number: customerPhoneNumber,
				});
			}
		});
	};

	if (state.succeeded) {
		return (window.location = "/thank-you");
	}

	return (
		<Form className="w-100" onSubmit={handleFormSubmit}>
			<Form.Group controlId="name" className="mb-3">
				<Form.Control name="name" placeholder="Name" type="text" />
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />
			<Form.Group className="mb-3" controlId="email">
				<Form.Control
					name="_replyto"
					type="email"
					placeholder="Email Address"
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />
			<Form.Group className="mb-3" controlId="telephone">
				<Form.Control
					type="tel"
					name="telephone"
					placeholder="Telephone Number"
				/>
			</Form.Group>

			<ValidationError
				prefix="Telephone"
				field="telephone"
				errors={state.errors}
			/>
			<Form.Group className="mb-3" controlId="message">
				<Form.Control
					name="message"
					placeholder="Message"
					as="textarea"
					rows={3}
				/>
			</Form.Group>

			<ValidationError prefix="Message" field="message" errors={state.errors} />

			<Button
				size="small"
				className="btn btn-primary mt-3"
				type="submit"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				{btnText ?? "Send message"}
			</Button>
		</Form>
	);
}

export default ContactForm;
